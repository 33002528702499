<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 20:03:08
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.survey {
  display: flex;
  flex-direction: row;
  .tip-view {
    min-height: 48px;
    line-height: 26px;
    padding: 20px;
    background-color: #eaf1ff;
    color: #447df5;
    font-size: 16px;
    box-sizing: border-box;
    margin: 0 12px 12px 8px;
    border-radius: 4px;
  }
  .empty-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-img {
      width: 300px;
      height: 180px;
      margin-top: 40px;
    }
    .empty-title {
      font-size: 18px;
      font-weight: 500;
      color: #0A1C39;
      margin-top: 10px;
    }
  }
  .list-info {
    min-width: calc(1216.25px - 388px);
    max-width: calc(1216.25px - 388px);
    height: 970px;
    padding-top: 25px;
    margin-bottom: 25px;
    overflow-y: overlay;
    .list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .loading {
      width: 100%;
      font-size: 16px;
      text-align: center;
    }
  }
  .user-info {
    min-width: 388px;
    height: 100%;
    padding-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    .user-header {
      width: 100%;
      // height: 340px;
      height: 300px;
      background-color: #f5f8ff;
      border-radius: 4px;
      .user-deatil {
        display: flex;
        flex-direction: row;
        .user-photo {
          width: 88px;
          height: 88px;
          border-radius: 44px;
          border: 4px solid white;
          background-color: #f7f8fa;
          margin-top: 25px;
          margin-left: 38px;
        }
        .name-detail {
          margin-top: 44px;
          display: flex;
          flex-direction: column;
          .user-name {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin-left: 10px;
          }
          .property-detail {
            display: flex;
            flex-direction: row;
            margin-top: 4px;
            .user-property {
              margin-left: 10px;
              font-size: 14px;
            }
            .property-credit {
              margin-left: 30px;
            }
            .property-content {
              font-weight: bold;
              color: #447df5;
            }
          }
        }
        .signin-img {
          width: 30px;
          height: 30px;
          margin-left: 37px;
          margin-top: 62px;
          pointer-events: auto;
        }
      }
      .exchange-btn {
        margin-left: 37px;
        margin-top: 22px;
        width: 323px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: white;
        text-align: center;
        font-weight: bold;
        border-radius: 4px;
        background-color: #447df5;
        background-image: linear-gradient(#79b4fb, #447df5);
      }
      .perfect-credit-title {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-left: 38px;
        margin-top: 24px;
      }
      .credit-detail {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 38px;
        margin-top: 16px;
        .credit-content {
          color: #666666;
          font-size: 14px;
        }
        .info-update-view {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .info-update-title {
          color: #666666;
          font-size: 14px;
          margin-left: 56px;
        }
        .update-img {
          // position: absolute;
          // display: inline;
          width: 20px;
          height: 20px;
          margin-left: 11px;
          // margin-top: 10px;
          // pointer-events: auto;
        }
      }
      .slider-bg {
        padding: 0 13px 18px 38px;
        margin-top: 10px;
        .slider-icon {
          color: white;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          width: 28px;
          height: 33px;
          line-height: 33px;
          background-image: url("../../assets/images/icon_credit_slider.png");
          background-size:100% 100%;
          background-repeat:no-repeat;
          margin-left: calc(30% - 16px); 
        }
        .slider-div {
          background-color: #E2EBFF;
          border-radius: 5px;
          height: 10px;
        }
        .slider-select-div {
          background-image: linear-gradient(to right, #F4E4C6, #C79E5D);
          border-radius: 5px;
          height: 10px;
          width: 30%;
        }
      }
    }
    .announcement-info {
      width: 100%;
      height: 237;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 0 15px #eef0f7;
      margin-top: 20px;
      padding-left: 36px;
      padding-right: 36px;
      padding-bottom: 25px;
      .header-title {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        margin-top: 16px;
      }
      .announcement-content {
        margin-top: 16px;
        p {
          color: #666666;
          font-size: 14px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .wx-public-info {
      width: 100%;
      min-height: 221px;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 0 15px #eef0f7;
      margin-top: 20px;
      text-align: center;
      p {
        color: #333333;
        font-size: 20px;
        margin-top: 16px;
      }
      img {
        width: 144px;
        height: 144px;
        margin-top: 13px;
        display: inline;
      }
    }
    .invitation-info {
      width: 100%;
      min-height: 122px;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 0 15px #eef0f7;
      margin-top: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        color: #333333;
        font-size: 20px;
        margin-top: 16px;
      }
      .invitation-btn {
        width: 323px;
        min-height: 48px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        border-radius: 4px;
        background-image: linear-gradient(#79b4fb, #447df5);
        margin-top: 11px;
        line-height: 48px;
      }
    }
  }
}
</style>
<template>
  <div class="survey">
    <div class="list-info" ref="body" @scroll="needLoad&&scrollLoad()">
      <div class="tip-view">
        参加问卷调查的奖励说明：
        <br>1、新注册会员的账户里将打入200金币，（合2元钱）。
        <br>2、注册会员参与问卷调查，每完成1份成功问卷，按照问卷长度和难易程度，奖励区分为：100金币（合1元），200金币（合2元），300金币（合3元）。
        <br>3、因为未满足甄别条件或答题时间过短等不认真答题的行为，问卷可能被系统判为无效则无奖励。
        <br>4、奖励提取政策：满2000金币（合20元）以上金额可以提取到支付宝账号，在兑换页面点击提取按钮，当周周末由平台进行统一打款。您的支付宝账号请务必保证可用。
      </div>
      <div class="empty-view" v-if="tasklist.length<=0">
        <img class="empty-img" src="@assets/images/icon_empty.png">
        <div class="empty-title">暂无数据</div>
      </div>
      <div ref="container" class="list">
        <survey-list
        v-on:hiddenEvent="hiddenClick"
        :isShow="true"
        :taskData="item"
        v-for="item in tasklist"
        :key="item.projec_id"
        ></survey-list>
      </div>
      <div class="loading" v-if="loading">
          <a-spin />
          加载中...
      </div>
    </div>
    <div class="user-info">
      <div class="user-header">
        <div class="user-deatil">
          <img class="user-photo" src="@assets/images/avatar.png" />
          <div class="name-detail">
            <span class="user-name">{{ userInfo.member_name }}</span>
            <div class="property-detail">
              <span class="user-property"
                >金币：<span class="property-content">{{ userInfo.balance }}</span></span
              >
              <span class="user-property property-credit"
                >信用值：<span class="property-content">{{ userInfo.credits }}</span></span
              >
            </div>
          </div>
          <!-- <img class="signin-img" src="@assets/images/ico_signin.png" @click="signInClick"/> -->
        </div>
        <div class="exchange-btn" @click="exchangeClick">我要兑换</div>
        <p class="perfect-credit-title">完善信用值</p>
        <div class="credit-detail">
          <div class="credit-content">+10信用值</div>
          <div class="info-update-view" @click="updateUserInfo">
            <div class="info-update-title">背景资料更新</div>
            <img
              class="update-img"
              src="@assets/images/ico_update.png"
            />
          </div>
        </div>
        <!-- <div class="slider-bg">
          <div class="slider-icon">V10</div>
          <div class="slider-div">
            <div class="slider-select-div"></div>
          </div>
        </div> -->
      </div>
      <div class="announcement-info">
        <p class="header-title">最新公告</p>
        <div class="announcement-content" v-for="item in noticelist" :key="item.notice_id">
          <p @click="checkOrderDetail(item.notice_id)">{{ item.notice_title }}</p>
        </div>
      </div>
      <!-- <div class="wx-public-info">
        <p>绑定公众号直接兑换微信红包</p>
        <img src="@assets/images/ico_wvpublic.png" />
      </div> -->
      <div class="invitation-info">
        <p>您知道吗?好友也能帮您赚钱</p>
        <div class="invitation-btn" @click="recruitClick">召集好友 奔跑吧</div>
      </div>
    </div>
    <a-modal
      :visible="visible"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <p>
        此调查，如果提示您参加过或者您不愿意参加，您可以隐藏本项目。隐藏后，您将无法再参与本项目，请确认是否隐藏？
      </p>
    </a-modal>
  </div>
</template>

<script>
import Lib from "@/lib";
import SurveyList from "@/components/SurveyList.vue";
import Storage from "@/utils/storage";
import { message } from 'ant-design-vue';
import { fetchUserInfo, fetchnoticelist } from "@/api/user.js"
import { fetchMemberlist } from "@/api/commodity.js"

export default {
  name: "SurveyCenter",
  components: {
    SurveyList,
  },
  data() {
    return {
      loading: false,
      needLoad: true,
      visible: false,
      userInfo: {},
      tasklist: [],
      noticelist: [],
      page_size: '50',
      page: 1,
    };
  },
  created () {
    this.initData()
  },
  methods: {
    /// 初始化数据
    initData() {
      this.userInfoData()
      this.getNoticelist()
      this.fetchTasklist()
    },
    /// 兑换
    exchangeClick: function () {
      this.$router.push({ name: "Exchange" });
    },
    /// 召集好友
    recruitClick: function () {
      this.$router.push({ name: "Recruit" });
    },
    /// 签到
    signInClick: function() {
      message.info('签到成功！！！');
    },
    /// 更新资料
    updateUserInfo() {
      this.$router.push({ name: "User" });
    },
    /// 查看订单详情
    checkOrderDetail(id) {
      this.$router.push({ name: "SurveyNews", query: { noticeId: id } });
    },
    hiddenClick: function () {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk(e) {
      this.visible = false;
    },
    /// 获取公告消息
    async getNoticelist() {
      try {
        let result = await fetchnoticelist({
          member_id: this.userId,
          token: this.userToken, 
          page: 1,
          page_size: 5
        });  
        this.noticelist = result.notice_list
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取用户信息
    async userInfoData() {
      try {
        let result = await fetchUserInfo({
          member_id: this.userId,
          token: this.userToken, 
        });  
        this.userInfo = result
        /// 更新数据
        Storage.set("userInfo", result);
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取任务列表
    async fetchTasklist() {
      this.loading = true;
      try {
        let result = await fetchMemberlist({
          member_id: this.userId,
          token: this.userToken, 
          page_size: this.page_size,
          page: this.page
        });  
        this.loading = false;
        if (result.total == 0 || result.project_list.length < this.page_size ) {
          this.needLoad = false;
        }
        result.project_list.map(item => {
          let key = item.project_id;
          if (result.finish_project.hasOwnProperty(key)) {
            item["isfinish"] = true;
          }else {
            item["isfinish"] = false;
          }
        });
        this.tasklist.push(...result.project_list);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    /// 滚动list操作
    scrollLoad() {      
      Lib.debounce(() => {
        if (this.judgeBom() && !this.loading) {
          this.page++;
          this.fetchTasklist()
        } 
      },this);
    },
    judgeBom() {
      const ContainerDom = this.$refs.container
      let documentHeight = ContainerDom.scrollHeight; // 文档高度
      let containerHeight = this.$refs.body.offsetHeight // 容器高度
      let scrollHeight = this.$refs.body.scrollTop; // 滚动条滚动的距离
      return documentHeight - containerHeight <= scrollHeight + 5;
    },
  },
};
</script>
