<!--
 * @Author: Su
 * @Date: 2020-11-27 12:10:37
 * @LastEditTime: 2020-11-28 17:43:07
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.survey-list {
  width: 386px;
  min-width: 386px;
  min-height: 158px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 8.125px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  transition: all 0.6s;
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.17);
  }
  .title-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
    p {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      margin-left: 15px;
    }
    .hidden-btn {
      color: #999999;
      font-size: 14px;
      margin-right: 15px;
      margin-top: 2px;
    }
  }
  .detail-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    margin-top: 12px;
    padding: 0 14px;
    .code-view {
      background-color: #f0f5ff;
      border: 1px solid #a2c0ff;
      border-radius: 4px;
      color: #447df5;
      font-size: 14px;
      width: 116px;
      height: 28px;
      line-height: 27px;
      text-align: center;
    }
    .time-view {
      background-color: #f2fff8;
      border: 1px solid #64d8a7;
      border-radius: 4px;
      color: #11c479;
      font-size: 14px;
      width: 107px;
      height: 28px;
      line-height: 27px;
      text-align: center;
      margin-left: 8px;
    }
    .result-view {
      background-color: #fef1f0;
      border: 1px solid #f56c6d;
      border-radius: 4px;
      color: #f8797a;
      font-size: 14px;
      width: 124px;
      height: 28px;
      line-height: 27px;
      text-align: center;
    }
  }
  .operation-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 13px;
    align-items: center;
    p {
      font-size: 22px;
      color: #f57144;
      margin-left: 18px;
    }
    button {
      width: 237px;
      height: 42px;
      background-color: #447df5;
      font-size: 16px;
      font-weight: bold;
      color: white;
      border-radius: 4px;
      margin-right: 14px;
    }
    .finish {
      background-color: #0A1C3952;
    }
  }
}
</style>

<template>
  <div class="survey-list" v-show="isShow">
    <div class="title-content">
      <p class="title">{{ taskData.project_title }}</p>
      <!-- <p class="hidden-btn" v-on:click="hiddenItem">隐藏</p> -->
    </div>
    <div class="detail-content">
      <span class="code-view">{{ taskData.pay_type == 1 ? "即时发放" : "审核发放" }}</span>
      <span class="time-view">时长：{{ taskData.validate_time | format }}</span>
      <!-- <span class="result-view">成功率：15%</span> -->
    </div>
    <div class="operation-content">
      <p>{{ taskData.coin }}金币</p>
      <button class="finish" v-if="taskData.isfinish">已完成</button>
      <button v-else @click="startQuestion(taskData.project_id)">马上参与</button>
    </div>
  </div>
</template>

<script>
import { formatSeconds } from "@/utils/filters";

export default {
  props: {
    isShow: {
      isShow: Boolean,
      default: true,
    },
    taskData: {}
  },
  filters: {
    format(value) {
      return formatSeconds(value)
    }
  },
  mounted() {
  },
  methods: {
    hiddenItem: function () {
      this.$emit("hiddenEvent");
    },
    startQuestion(id) {
      this.$router.push({ name: "SurveyStartQuestion", query: { projectId: id } });
    },
  },
};
</script> 